@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";



.noty_theme__mint {
	&.noty_bar{
		margin: 4px 0;
	    overflow: hidden;
	    border-radius: 2px;
	    position: relative;
	    padding: 15px 20px;
	    box-shadow: 0px 5px 20px -13px #000;
	    border-radius: 8px;
	    // border: 1px solid #eee;
	}
	&.noty_type__warning {
	    background-color: #ffeeba;
	    border-bottom: 1px solid #fff3cd;
	    color: #856404;
	}
    
}
 