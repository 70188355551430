@charset "UTF-8";
@import "../noty";
@import "../animations";
@import "stylesheet/animate.min.css";
@import "bootstrap/bootstrap";
@import "stylesheet/header";
@import "stylesheet/carousel";
@import "stylesheet/home-elements";
@import "stylesheet/footer";


body {
	font-family: 'Source Sans Pro', sans-serif;
}
.btn, button {
	outline: none !important;
}
.no-padding {
	padding: 0px;
}


.owl-carousel .owl-item img {
    display: initial;
    width: auto;
}


.owl-theme {
	.owl-dots {
		text-align: center;
		.owl-dot span{
			width: 10px;
		    height: 10px;
		    margin: 5px 7px;
		    background: #D6D6D6;
		    display: block;
		    -webkit-backface-visibility: visible;
		    transition: opacity .2s ease;
		    border-radius: 30px;
		}
	}
	.owl-nav{
	    position: absolute;
	    top: 30%;
	    width: 100%;
	    .owl-prev{
			position: absolute;
	    	left: 30px;
	    	&.disabled{
	    		opacity: 0;
	    	}
		}
		.owl-next{
			position: absolute;
	    	right: 30px;
	    	&.disabled{
	    		opacity: 0;
	    	}
		}
	}



}



/* Icon 1 */
#nav-icon2 {
  width: 35px;
  height: 26px;
  position: relative;
  outline: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: #2f9ba7;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(even) {left: 50%;border-radius: 0 9px 9px 0;}
    &:nth-child(odd) {left:0px;border-radius: 9px 0 0 9px;}
    &:nth-child(1),&:nth-child(2) {top: 0px;}
    &:nth-child(3),&:nth-child(4) {top: 10px;}
    &:nth-child(5),&:nth-child(6) {top: 20px;}
  }
}
#nav-icon2.open{
  span{
    &:nth-child(1),&:nth-child(6) {-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);}
    &:nth-child(2),&:nth-child(5) {-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);}
    &:nth-child(1) {left: 3px;top: 7px;}
    &:nth-child(2) {left: calc(50% - 5px);top: 7px;}
    &:nth-child(3) {left: -50%;opacity: 0;}
    &:nth-child(4) {left: 100%;opacity: 0;}
    &:nth-child(5) {left: 3px;top: 17px;}
    &:nth-child(6) {left: calc(50% - 5px);top: 17px;}
  }
}



/*************  [ FOOTER TOP ]   ***************/
    .icon_left{
        width: 15px;
        background-repeat: no-repeat;
        background-position: center;
        height: 35px;
        float: left;
        border-radius: 25px;
        background-image: url($images_cdn_url + '/arrow_to_left.svg');
        background-size: 10px;
    }

    .icon_right{
        width: 15px;
        background-repeat: no-repeat;
        background-position: center;
        height: 35px;
        float: left;
        border-radius: 25px;
        background-image: url($images_cdn_url + '/arrow_to_right.svg');
        background-size: 10px;
    }
    .carousel-control .icon_left{width: 30px;background-image: url($images_cdn_url + '/left_w.svg');}
    .carousel-control .icon_right{width: 30px;background-image: url($images_cdn_url + '/right_w.svg');}
/*************  [ END FOOTER TOP ]   ***************/


/*************  [ RESULT SEARCH ]   ***************/
    .right_header{
        float: right;
        width: 100%;
        position: relative;
    }
    .od_times .right_header{
        margin-bottom: 5px;
    }
    .nav_left{
        width: 39px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 10px;
        float: left;
        background-image: url($images_cdn_url + '/next_slider.png');
    }
    .nav_right{
        width: 39px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 10px;
        float: right;
        background-image: url($images_cdn_url + '/previe_slider.png');
    }
    .ul_date {
            margin: 0px;
            padding: 0px;
            list-style: none;
            float: right;
            border:1px solid #c7c8ca;
            border-bottom: 0px;
            border-radius: 8px 8px 0px 0px;
            overflow: hidden;
    }
    .ul_date li{
        float: left;
        width: 70px;
        padding: 10px 0px;
        line-height: 22px;
    }
    .ul_date li:nth-child(odd){
        background-color: #f4f4f4;
    }
    .date_day{
        float: left;
        width: 100%;
        text-align: center;
        color:#6d6e71;
        font-size: 22px;
        font-weight: bold;
    }
    .date_value{
        float: left;
        width: 100%;
        text-align: center;
        color:#6d6e71;
        font-size: 15px;
    }
    .item_result{
        float: left;
        width: 100%;
        border: 1px solid #c7c8ca;
        border-bottom: 0px;
    }
    .liste_doc_result .item_result:first-child{
        border-radius: 8px 8px 0px 0px;
    }
    .liste_doc_result .item_result:last-child{
        border-bottom: 1px solid #c7c8ca;
        border-radius: 0px 0px 8px 8px ;
    }
    .profil_left{
        float: left;
        width: 31.7%;
        padding: 22px 0px;
    }
    .profil_img{
        background-repeat: no-repeat;
        background-position: center;
        width: 110px;
        height: 110px;
        margin-left:  auto;
        margin-right: auto;
        display: block;
        position: relative;
        border-radius: 8px;
        margin-bottom: 6px;
        background-color: #eee;
    }
    .map_icon{
        width: 31px;
        height: 44px;
        position: absolute;
        left: -15px;
        top: -5px;
        background-image: url($images_cdn_url + '/number_doctor.png');
        font-family: 'terminal_dosismedium', sans-serif;
        text-align: center;
        font-size: 16px;
        color: #fff;
        line-height: 29px;
    }
    .top_info_ab_doc{
        margin-top: 0px;
    }
    .info_doc{
        float: left;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .name_doc{
        color: #00acb1;
        width: 100%;
        text-align: center;
        font-size: 17px;
        float: left;
        font-family: 'terminal_dosisbold', sans-serif;
        margin-bottom: 6px;
    }
    .name_doc .dr{
        font-family: 'terminal_dosisbook', sans-serif;
    }
    .adresse_doc{
        float: left;
        width: 100%;
        text-align: center;
        font-size: 13px;
        font-family: 'terminal_dosisbook', sans-serif;
        color:#6d6e71;
    }
    .btn_rdv_min{
        color: #fff;
        text-align: center;
        background-color: #ba0d67;
        font-family: 'terminal_dosisbold', sans-serif;
        text-decoration: none;
        display: block;
        font-size: 14px;
        width: 142px;
        border-radius: 6px;
        position: absolute;
        padding: 7px 0px;
        left: 50%;
        margin-left: -71px;
        top: -34px;
    }
    .btn_rdv_min:hover{
        background-color: #8a094c;
        color:#fff;
        text-decoration: none;
    }
    .profil_left:hover .btn_rdv_min{
        top: 5px;
    }
    .profil_left:hover .name_doc,.profil_left:hover .adresse_doc{
        visibility: hidden;
    }
    .table_right{
        float: left;
        display: table;
        position: relative;
        display: none;
    }
    .ul_date_value {
            margin: 0px;
            padding: 0px;
            list-style: none;
            float: left;
            border-bottom: 0px;
            overflow: hidden;
    }
    .ul_date_value li{
        float: left;
        width: 70px;
        padding: 10px 0px;
        line-height: 22px;
        height: 100%;
        display: table-cell;
        vertical-align: top;
        position: relative;
    }
    .ul_date_value li:nth-child(odd) {
        background-color: #f4f4f4;
    }
    .times{
        float: left;
        width: 70.5px;
        background-color: #fff;
        padding-top: 15px;
    }
    .ul_date_value2{
        height: 216px;
        text-align: center;
        margin: 0px;
        padding: 0px;
        list-style: none;
        float: left;
        border-bottom: 0px;
        width: 100%;
        overflow: visible;
    }
    .ul_date_value2 li {
        float: left;
        width: 100%;
    }
    .od_times .ul_date_value2 li{
        padding: 0px 5px;
    }

    .times .ul_date_value2 a:hover{
        background-color: #b90e66;
        color:#fff;
    }
    .liste_times{
        float: left;
        position: relative;
        margin-bottom: 5px;min-height: 45px;
    }
    .liste_times .times:nth-child(odd) {
            background-color: #f4f4f4;
    }
    .more{color:#32B2C5 !important;}
    .more:hover{color:#fff !important;}
    .time_empty{
        width: 94%;
        max-width: 343px;
        border: 1px solid #e5e4e4;
        background-color: #f0f0f0;
        text-align: center;
        margin: auto;
        position: absolute;
        font-family: 'terminal_dosismedium', sans-serif;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 84px;
        padding: 11px 0px;
    }
    .time_empty .show_number{
        color: #00acb1;
        font-family: 'terminal_dosismedium', sans-serif;
        float: left;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
    }
    .number{display: none;}
/*************  [ RESULT SEARCH ]   ***************/


/*************  [ Header Docteur Abonne ]   ***************/
    .header_doc_abonne{
        margin-top: -70px;
    }
    .profil_ab_doc{
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 140px;
        height: 139px;
        float: left;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
    .profil_ab_doc img{
        width: 100%;
    }
    .doc_empty{
        background-color: #dfe4e9;
        background-size: 80% !important;
        cursor: auto;
    }
    .img_icon{
        width: 36px;
        height: 33px;
        position: absolute;
        background-color: #00a471;
        bottom: 0px;
        left: 0px;
        border-radius: 0px 66px 0px 0px;
    }
    .icon_images{
        background-image: url($images_cdn_url + '/images.svg');
        background-repeat: no-repeat;
        background-position: 7px 12px;
        background-size: 100%;
        color:#fff;
        width: 36px;
        height: 33px;
    }
    .info_ab_doc{
        float: right;
        width: calc(100% - 175px);
        position: relative;
        margin-top: 0px;
    }
    .active_scroll .info_ab_doc{
        width: calc(100% - 90px);
    }
    .doctor_name{
        float: left;
    }
    .badge{
        display: inline-block;
        float: left;
        margin-top: 4px;
        margin-left: 5px;
    }
    .top_info_ab_doc{
        float: left;
        width: 100%;
        position: relative;
    }
    .ab_doc_name_jurisdiction{
        float: left;
        width: 100%;
    }
    .ab_doc_name,.ab_doc_jurisdiction{
        float: left;
        width: 100%;
        color:#fff;
    }
    .active_scroll .ab_doc_jurisdiction{width: 77%;}
    .ab_doc_name{
        font-size: 22px;
        padding: 0px;
        margin: 0px;
        font-weight: normal;
        margin-bottom: 5px;
        margin-top: 0px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ab_doc_jurisdiction{
        font-size: 16px;
        padding: 0px;
        margin: 0px;
        font-weight: normal;
    }
    .ab_doc_views{
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 14px;
        color: #fff;
    }
    .ab_doc_views i{
        margin-right: 5px;
    }
    .bottom_info_ab_doc{
        float: left;
        width: 100%;
        margin-top: 40px;
    }
    .bottom_info_ab_doc ul{
        padding: 0px;
        margin:0px;
        list-style: none;
        float: left;
        width: 100%;
    }
    .bottom_info_ab_doc ul li {
        float: left;
        margin-right: 48px;
    }
    .active_scroll .bottom_info_ab_doc ul li{
        margin-right: 45px;
    }
    .bottom_info_ab_doc ul li:last-child{
        margin-right: 0px;
    }
    .bottom_info_ab_doc ul li a{
        float: left;
        width: 100%;
        text-align: left;
        text-decoration: none;
        font-size: 18px;
        color:#6d6e71;
    }
    .bottom_info_ab_doc ul li a:hover,.bottom_info_ab_doc ul li a.active{
        color:#2f9aa2;
    }
    .div_info_doc{
        float: left;
        width: 100%;
        border: 1px solid #c7c8ca;
        border-radius: 8px;
        margin-top: 30px;
        color: #6d6e71;
        font-size: 14px;
        padding: 30px 15px;
    }

    .div_info_doc p{
        margin-bottom: 0px;
    }
    .adresse_block{
        float: left;
        width: 80%;
        line-height: 20px;
    }
    .title_adresse {
        float: left;
        width: 100%;
        font-weight: bold;
        margin-bottom: 15px;
        color: #6d6e71;
    }

    .see_in_map{
        color: #00a471;
        margin-top: 32px;
        float: left;
    }
    .see_in_map i{
        font-size: 20px;
        margin-right: 5px;
    }
    .block_1_doc{
        float: left;
        width: 100%;
    }
    // .non_abonne .div_info_doc {
    //  margin-top: -35px;
    // }
    .div_info_doc hr{
        float: left;
        border-top: 0px solid #e8e8e8;
        width: 100%;
        margin: 30px 0px;
    }
    .block_2_doc{
        float: left;width: 100%;
    }
    .block_3_doc,.block_4_doc,.block_5_doc,.block_6_doc{
        float: left;
        width: 100%;
    }
    .type_payement{
        float: left;
    }
    .payement_title{
        float: left;
        width: 100%;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .payment_icon{
        float: left;
        width: 34px;
        height: 24px;
    }
    .no_desktop{
        display: none;
    }
    .type_payement ul{
        float: left;
        list-style: none;
        padding: 0px;
        margin: 0px;
    }
    .type_payement li{
        float: left;
        margin-right: 15px;
    }
    .type_payement li:last-child{
        margin-right: 0px;
    }
    .tarif_consultation{
        float: left;
    }
    .tarif_consultation .title_tarif{
        float: left;
        width: 100%;
        font-weight: bold;
        margin-bottom: 10px;
        color: #6d6e71;
    }
    .specialites,.graduates{
        float: left;
        width: 100%;
    }
    .liste_specialites{
        float: left;
        width: 100%;
    }
    .liste_specialites a{
        margin-right: 5px;
        padding: 4px 10px 6px 10px;
        color: #fff;
        border-radius: 15px;
        font-size: 14px;
        float: left;
        margin-bottom: 5px;
    }
    .map_doc{
        border: 1px solid #c7c8ca;
        overflow: hidden;
        border-radius: 6px;
        float: left;
        width: 100%;
        height: 255px;
        margin-bottom: 30px;
        background-color: #eee;
    }
    .map_doc:before{
        content: "";
        width: 80px;
        height: 80px;
        background-image: url($images_cdn_url + '/gps.svg');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        z-index: 0;
    }
    .non_abonne .map_doc{
        height: 400px;
        margin-bottom: 30px;
    }
    .graduates ul{
        float: left;
        width: 100%;
        padding: 0px;
        line-height: 25px;
        margin:0px;
        margin-left: 15px;
        color:#6d6e71;
    }
    .contributions{
        float: left;
        width: 100%;
    }
    .ul_contributions{
        float: left;
        width: 100%;
        padding: 0px;
        list-style: none;
    }
    .ul_contributions li{
        float: left;
        width: 32%;
        margin-right: 2%;
    }
    .ul_contributions li:last-child{
        margin-right: 0px;
    }
    .contributions_item{
        width: 100%;
        float: left;
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 6px;
        border: 1px solid #e8e8e8;
        position: relative;
        overflow:hidden;
    }
    .img_post_item{
        float: left;
        width: 100%;
        height: auto;
    }
    .icon_play{
        width: 57px;
        height: 57px;
        background-color: #fff;
        border: 1px solid #c04083;
        border-radius: 45px;
        background-image: url($images_cdn_url + '/play.svg');
        background-position: center;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: calc(50% - 28px);
        top: calc(50% - 28px);
        transition: transform .3s;
    }
    .contributions_item:hover .icon_play{
        transform: scale(1.3);
    }
    button.btn .phone_icon {
        background-image: url($images_cdn_url + '/phone.svg');
        width: 23px;
        height: 23px;
        left: 35px !important;
        top: 11px !important;
        background-size: 100%;
    }
    @media screen and (max-width: 490px){
        button.btn .phone_icon {
            left: 45px !important;
        }
    }
    .link_contributions{
        width: 100%;
        text-align: center;
        color: #00a471;
        float: left;
        margin-top: 10px;
        font-weight: bold;
        font-size: 12px;
    }
    .aside_doc_abonne {
        float: left;
        width: 100%;
        padding: 0px 15px;
        background-color: #fff;
        border-top: 0px;
    }
    .aside_doc_abonne .times .ul_date_value2 a,.od_times .times .ul_date_value2 a{
        width: 100%;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        line-height: 17px;
        padding-top: 3px;
        font-size: 14px;
        border: 0px solid #a61057;
        border-radius: 4px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none;
        margin-bottom: 5px;
        background-color: #32B2C5;
        padding-bottom: 3px;
        position: relative;
    }
    .video-call-calendar{
        position: relative;
    }
    .video-call-calendar:before{
        background-image: url($images_cdn_url + '/video_consultation.svg');
        background-position: center;
        background-size: 16px;
        background-repeat: no-repeat;
        position: absolute;
        content: "";
        width: 14px;
        height: 11px;
        background-color: #b12161;
        right: -5px;
        top: -3px;
        border-radius: 3px;
    }
    .icon_VideoConsultation{
        background-image: url($images_cdn_url + '/video_consultation.svg');
        background-position: center;
        background-size: 16px;
        background-repeat: no-repeat;
        width: 15px;
        height: 11px;
        background-color: #b12161;
        display: inline-block;
        border-radius: 2px;
    }
    .aside_content .VideoConsultation{
      font-family: "terminal_dosismedium", sans-serif;
      font-size: 16px;
    }
    .aside_content .icon_VideoConsultation{
      background-size: 21px;
      background-repeat: no-repeat;
      width: 20px;
      height: 14px;
    }
    .aside_doc_abonne .liste_times .times:nth-child(odd),.aside_doc_abonne .ul_date li:nth-child(odd){
        background-color: transparent;
    }
    .aside_doc_abonne .ul_date{
        border: 0px;
        border-bottom: 1px solid #c7c8ca;
    }
    .aside_doc_abonne .times{
        width: 16.66%;
        padding-left: 5px;
        padding-right: 5px;
    }
    .aside_doc_abonne .ul_date li{
        width: 16.66%;
        padding-left: 5px;
        padding-right: 5px;
    }
    .disabled-rdv {
        opacity: .5;
        cursor: default;
        background-color: #8b8b8b!important;
    }
    .aside_doc_abonne .times .ul_date_value2 a:hover,.od_times .times .ul_date_value2 a:hover {
        background-color: #b90e66;
        color: #fff;
    }
    .aside_doc_abonne .date_day {
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
    }
    .aside_doc_abonne .date_value{font-size: 14px;margin-top: -5px;}
    .aside_doc_abonne .nav_right{position: absolute;right: 0px;}
    .aside_doc_abonne .nav_left{position: absolute;left: 0px;}
    .dic_nav_left{position: absolute;left: -10px;top:10px;color:#00a471;font-size: 30px;}
    .dic_nav_right{position: absolute;right: -10px;top:10px;color:#00a471;font-size: 30px;}




    .ul_date_value2 .date_top{
        list-style: none;
        margin: 0px;
        padding: 0px;
        overflow: visible;
    }
    .aside_doc_abonne .ul_date_value2{height: auto;}
    .aside_doc_abonne .liste_times {
        float: left;
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid #c7c8ca;
        padding-bottom: 8px;
        width: 100%;
        min-height: 214px;
        overflow: auto;
    }
    .show_all_times{
        float: left;
        width: 100%;
        text-align: center;
        color:#CA2952;
        font-size: 16px;
        margin-top: 7px;
        margin-bottom: 15px;
        text-decoration: none;
    }
    .show_all_times.disabled-link {
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.5;
    }
    .result_aside .link_rdv_num{
        float: left;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
        padding: 11px 0px;
        border: 1px solid #32B2C5;
        background-color: #32B2C5;
    }
    .report_page{
        float: left;
        width: 100%;
        text-align: center;
        color:#6d6e71;
        font-size: 14px;
    }
    .rdv_online{
        float: left;
        width: 100%;
        background-color: #00a471;
        color: #fff;
        font-size: 20px;
        text-align: center;
        margin-top: -57px;
        padding: 13px 40px;
    }
    .date_time{
        background-image: url($images_cdn_url + '/date_time.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 31px;
        float: left;
    }
    .section_body_doc .rdv_numero {
        float: left;
        width: 100%;
        margin-top: 38px;
        max-width: 600px;
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
    .section_body_doc .messages_phone{
        width: 100%;
        float: left;
        text-align: center;
        margin: 30px 0px;
    }
    .messages_phone p{
        margin: 0px;
    }
    .section_contact_us .link_rdv_num.a_num {
        background-color: #32B2C5;
        border: 1px solid #fff;
        color: #ffffff;
    }
    .section_body_doc .link_rdv_num:hover{
        color: #32B2C5;
        background-color: #ffffff;
    }
    /*.non_abonne .rdv_online{
        padding: 14.5px 40px;
    }*/
    .non_calendar{
        float: left;
        width: 100%;
    }
    .non_calendar_icon{
        width: 50px;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        display: block;background-size: 100%;
        background-image: url($images_cdn_url + '/non_calendar.svg');
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .non_calendar p{
        width: 100%;
        text-align: center;
        font-size: 16px;
        color:#6d6e71;
        line-height: 18px;
        margin-bottom: 2px;
    }
    .other_doctors{
        float: left;
        width: 100%;
        margin-top: 30px;
        background-color: #fff;
    }
    .title_other_doctors{
        float: left;
        width: 100%;
        background-color: #00a471;
        color: #fff;
        font-size: 20px;
        text-align: center;
        padding: 13px 40px;
    }
    .other_doc_items{
        border: 1px solid #c7c8ca;
        float: left;width: 100%;
        padding: 30px 15px 30px 15px;
    }
    .other_doc_item{
        float: left;
        width: 100%;
        border: 1px solid #c7c8ca;
        padding: 15px 0px;
        margin-bottom: 15px;
        border-radius: 4px;
    }
    .other_doc_items .other_doc_item:last-child{
        margin-bottom: 0px;
    }
    .od_times{
        float: left;width: 50%;
    }
    .od_profil_info{
        float: left;
        width: 45%;
        text-align: center;
    }
    .od_img{
        width: 77px;
        height: 77px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        border-radius: 4px;
        overflow: hidden;
        background-color: #eee;
    }
    .od_name{
        float: left;width: 100%;
        text-align: center;
        font-size: 14px;
        color:#32B2C5;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .od_cat{
        float: left;width: 100%;
        text-align: center;
        font-size: 12px;
        color:#32B2C5;
    }
    .od_times .ul_date li {
        width: 33.3%;
        padding: 0px 0px 10px 0px;
        line-height: 16px;
        font-weight: bold;
    }
    .od_times .times{
        width: 33.3%;
        padding-top: 5px;
    }
    .od_times .ul_date_value2{height: auto;}
    .od_times .ul_date{border:0px;border-bottom: 1px solid #c7c8ca;}
    .od_times .liste_times .times:nth-child(odd),.od_times .ul_date li:nth-child(odd){background-color: #fff;}
    .date_day,.date_value{font-size: 14px;}

    .icon-to-right:before {
        content: "\e900";
    }
    .icon-doctor-f:before {
        content: "\e901";
        font-size: 92px;
        width: 100%;
        position: absolute;
        text-align: center;
        height: 100%;
        line-height: 140px;
        color: #fff;
    }
    .icon-doctor-f{
        background-color: #dfe4e9;
    }
    .icon-doctor-M:before {
        content: "\e902";
        font-size: 92px;
        width: 100%;
        position: absolute;
        text-align: center;
        height: 100%;
        line-height: 140px;
        color: #fff;
    }
    .icon_flag {
        width: 16px;
        height: 16px;
        background-image: url($images_cdn_url + '/flag.svg');
        background-repeat: no-repeat;
        display: inline-block;
        background-size: 100%;
        margin-right: 5px;
    }
    .icon_images {
        background-image: url($images_cdn_url + '/images.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        color: #fff;
        font-size: 18px;
        float: left;
        position: absolute;
        top: 3px;
        width: 23px;
        margin-left: 4px;
    }
    .icon_map_marker{
        content: url($images_cdn_url + '/map-marker.svg');
        width: 17px;
        height: auto;
        background-size: 100%;
    }
    .div_bg_img{
        /*background-image: url(../images/bg_file_ariane_docteur.jpg);*/
        background-color: #34426f;
        background-position: center bottom;
        width: 100%;
        height: 79px;
        margin-top: 0px;
    }
    .header_top_doc {
        // height: 148px;
        overflow: hidden;
    }
    .abonne .active_scroll.header_doc_abonne {
        width: 66%;
    }
    .rdv_online_scroll{display: none;}
    .switch_launge {
        font-size: 18px;
        padding: 11px 10px;
        border-radius: 2px;
        text-align: center;
        text-decoration: none !important;
        background-color: #32B2C5;
        color: #fff;
        float: left;
        margin-top: -15px;
        border: 1px solid #32B2C5;
        width: 153px;
    }
    .switch_launge:hover{
        color: #32B2C5;
        background-color: #fff;
    }
    #afficher_numero_2 .phoneNumber{
        height: 45px;
    }
    @media screen and (min-width: 640px) {
        .active_scroll .profil_ab_doc{width: 60px;height: 60px;border-radius: 4px;}
        .active_scroll .img_icon{display: none;}
        .active_scroll.header_doc_abonne{display: inline-block;}
        .active_scroll .bottom_info_ab_doc{margin-top: 18px;}
        .active_scroll_aside .aside_doc_abonne{background-color: #fff;}
        .active_scroll .bottom_info_ab_doc ul li a{color:#fff;}
        /*.active_scroll .ab_doc_name{font-size: 19px;}*/
        .active_scroll .icon-doctor-f:before {font-size: 47px;line-height: 60px;}
        .active_scroll .top_info_ab_doc {margin-top: -75px;float: right;width: 100% !important;}
        .non_abonne .profil_ab_doc{width: 60px;height: 60px;}
        .non_abonne .top_info_ab_doc {margin-top: 0px;}
        .non_abonne .info_ab_doc {width: calc(100% - 75px);}
        .non_abonne .result_aside{top: 69px;}
        .non_abonne .result_aside .aside_absolute {bottom: 72px !important;}
        .non_abonne .div_info_doc{min-height: 770px;}

    }
    .aside_content{float: left;top: -69px;position: relative;-webkit-transition: all 0s;transition: all 0s;}
    .header_top_doc.header_top_scroll {position: fixed;width: 100%;top: 0px;z-index: 10;}
    .header_top_scroll .div_bg_img{height: 79px;}
    .navbar-fixed-top{
        position: fixed !important;
    }





    .carousel {
        position: relative;
     	@media screen and (max-width: 414px) {
         	height: calc(100vh - 460px);
     	}
    }

    .carousel-item{
		@media screen and (max-width: 414px) {
			max-height: auto;
		}
	}

    .carousel-inner {
            position: relative;
            width: 100%;
            overflow: hidden;
    }

    .carousel-inner > .item {
            display: none;
            position: relative;
            -webkit-transition: .6s ease-in-out left;
            -o-transition: .6s ease-in-out left;
            transition: .6s ease-in-out left;
    }

    .carousel-inner > .item > a>img,
    .carousel-inner .item img {
            line-height: 1;
            width: 100%;
            height: auto;
    }

    @media all and (transform-3d) ,
    (-webkit-transform-3d) {
            .carousel-inner > .item {
                    -webkit-transition: -webkit-transform .6s ease-in-out;
                    -o-transition: -o-transform .6s ease-in-out;
                    transition: transform .6s ease-in-out;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-perspective: 1000px;
                    perspective: 1000px;
            }

            .carousel-inner > .item.active.right,
            .carousel-inner > .item.next {
                    left: 0;
                    -webkit-transform: translate3d(100%,0,0);
                    transform: translate3d(100%,0,0);
            }

            .carousel-inner > .item.active.left,
            .carousel-inner > .item.prev {
                    left: 0;
                    -webkit-transform: translate3d(-100%,0,0);
                    transform: translate3d(-100%,0,0);
            }

            .carousel-inner > .item.active,
            .carousel-inner > .item.next.left,
            .carousel-inner>.item.prev.right {
                    left: 0;
                    -webkit-transform: translate3d(0,0,0);
                    transform: translate3d(0,0,0);
            }
    }

    .carousel-inner > .active,
    .carousel-inner > .next,
    .carousel-inner>.prev {
            display: block;
    }

    .carousel-inner > .active {
            left: 0;
    }

    .carousel-inner > .next,
    .carousel-inner > .prev {
            position: absolute;
            top: 0;
            width: 100%;
    }

    .carousel-inner > .next {
            left: 100%;
    }

    .carousel-inner > .prev {
            left: -100%;
    }

    .carousel-inner > .next.left,
    .carousel-inner > .prev.right {
            left: 0;
    }

    .carousel-inner > .active.left {
            left: -100%;
    }

    .carousel-inner > .active.right {
            left: 100%;
    }

    .carousel-control {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 15%;
            text-align: center;
            text-shadow: 0 1px 2px rgba(0,0,0,.6);
            font-size: 20px;
            color: #fff;
            opacity: .5;
            background-color: rgba(0,0,0,0);
    }

    .carousel-control.left {
            background-image: -webkit-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
            background-image: -o-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
            background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.5) ),to(rgba(0,0,0,.0001) ));
            background-image: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
            background-repeat: repeat-x;
    }

    .carousel-control.right {
            right: 0;
            left: auto;
            background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
            background-image: -o-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
            background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.0001) ),to(rgba(0,0,0,.5) ));
            background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
            background-repeat: repeat-x;
    }


    #modalGallery .carousel-control.left,#modalGallery .carousel-control.right {
        background-image: none !important;
    }
    .carousel-control:focus,
    .carousel-control:hover {
            text-decoration: none;
            outline: 0;
            color: #fff;
            opacity: .9;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next,
    .carousel-control .icon-prev {
            display: inline-block;
            z-index: 5;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            color: #fff;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
            left: 50%;
            margin-left: -10px;
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
            right: 50%;
            margin-right: -10px;
    }

    .carousel-control .icon-next,
    .carousel-control .icon-prev {
            width: 20px;
            height: 20px;
            font-family: serif;
            line-height: 1;
    }

    .carousel-control .icon-prev:before {
            content: '\2039';
    }

    .carousel-control .icon-next:before {
            content: '\203a';
    }

    @media screen and (min-width:768px) {
            .carousel-control .glyphicon-chevron-left,
            .carousel-control .glyphicon-chevron-right,
            .carousel-control .icon-next,
            .carousel-control .icon-prev {
                    width: 30px;
                    height: 30px;
                    margin-top: -10px;
                    font-size: 30px;
            }

            .carousel-control .glyphicon-chevron-left,
            .carousel-control .icon-prev {
                    margin-left: -10px;
            }

            .carousel-control .glyphicon-chevron-right,
            .carousel-control .icon-next {
                    margin-right: -10px;
            }
    }

    .container:after,
    .container:before,
    .modal-footer:after,
    .modal-footer:before,
    .modal-header:after,
    .modal-header:before,
    .nav:after,
    .nav:before,
    .navbar-header:after,
    .navbar-header:before,
    .navbar:after,
    .navbar:before,
    .row:after,
    .row:before {
            content: " ";
            display: table;
    }

    .container:after,
    .modal-footer:after,
    .modal-header:after,
    .nav:after,
    .navbar-header:after,
    .navbar:after,
    .row:after {
            clear: both;
    }

    .center-block {
            display: block;
            margin-right: auto;
            margin-left: auto;
    }

    .iti-mobile .intl-tel-input.iti-container {
        z-index: 999999999;
        width: 81vw !important;
        margin-left: auto;
        margin-right: auto;
    }
    .dentiste{background-color: transparent !important;}

    .DontShow{display:none;}

    .comming_rdv_pr_box .rdv_profile_pr .photos_doc .photo_doc_bg{
        background-color: #eee;
    }
    .active_scroll.header_doc_abonne .ab_doc_views.font-tm{
        top: 97px;
    }
    .close-covid{
        float: right;
    }

    .header-covid{
        min-height: 45px;
        display: none;
        position: relative;
        z-index: 999999;
        width: 100%;
        padding: 1px 0px;
        top: 2px;
        background-color: #32B2C5;
        color: #fff;
        transition: all 0.2s;
    }
    .header-covid a{
        color: #fff;
    }
    .hide-covid{
      display: none;
    }
    .header-covid p{
      width: 95%;
    }
/*************  [ END Header Docteur Abonne ]   ***************/


.switch_launge_header{
	display: none;
	@media screen and (max-width:768px) {
		display: block;
	}
	position: absolute;
    top: 29px;
    right: 67px;
    font-size: 20px;
    color: #223057;
    text-decoration: none !important;
}
.availability-message{
	text-align: center;
    display: grid;
    min-height: 200px;
    align-items: center;
    margin-bottom: 0px;
}
.aside_doc_abonne{
	border-radius: 0px 0px 4px 4px;
}
.show-mobile{
	display: none;
	@media screen and (max-width:768px) {
		display: block;
	}
}

.phoneNumber_box{
	
	background-color: #202E56;
    border: 1px solid #202e56;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    font-size: 23px;
    display: none;
	@media screen and (max-width:768px) {
		display: flex;
	}
    flex-direction: row;
    justify-content: space-evenly;
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
}


.dropdown-menu{
	.inner{
		max-height: 30vh !important;
	}

}
@media screen and (max-width:768px) {
	.CallToAction{
		display: none;
	}

	footer{
		padding-top: 0px;
		*{
			&:not(p,.footer-bootom){
				display: none;
			}
		}
	}
	.terms_and_condition{
		float: left;
	}
}




// RTL STYLE

html[dir="rtl"] {



    

    body *{
        font-family: 'Cairo', sans-serif;
    }
    .owl-carousel{
        direction: initial;
    }

    .bootstrap-select .btn-light{
        padding: 0.6rem 2.5rem 0.6rem 1.5rem;
    }
    .bootstrap-select .dropdown-toggle .filter-option-inner-inner{
        text-align: start;
    }
  

	.btn-steps-action{
    &.space-between{
                flex-direction: row-reverse !important;
            }
    }

	.switch_launge_header{
		left: 67px;
		right: auto;
	}
	.ml-auto{
		margin-right: auto !important;
		margin-left: 0px !important;
	}
	.text-left{
		text-align: start !important;
	}
	.filter-option-inner-inner{
		text-align: start;
	}

	.carousel{
		.carousel-caption-img {
		    left: 0%;
		    right: auto;
		    top: -15%;
		    width: calc(100% - 276px);
		    .item1{
				left: 0%;
				right: auto;
		    }
		    .item2{
		    	left: 20%;
		    	right: auto;
		    }
		    .item3{
		    	left: 45%;
		    	right: auto;
		    }
		}

	}
	.search-section{
		.icon-heartbeat{
			margin-right: 5px;
			margin-left: auto;
		}
		.bootstrap-select .dropdown-toggle .filter-option-inner-inner{
    		padding-right: 10px;
		}
		.dropdown-item{
			text-align: start;
		}
	}
	.dropdown-header{
		text-align: start;
	}
	.bootstrap-select .dropdown-menu li a.opt {
	    padding-right: 2.25em;
	    text-align: start;
	}
	.results-section{
		.header{
			text-align: start;
			img{
				    margin-right: 0px;
				    margin-left: 15px;
			}
		}
	}
	.steps-title{
		text-align: start;
		display: block;
	}
	footer{
		text-align: start;
		.switch_launge{
			float: right;
		}

	}
	.CallToAction a{
		direction: ltr;
	}


	// @font-face {
	//     font-family: 'dinnextltarabic';
	//     src: url('dinnexltarabic.woff2') format('woff2'),
	//         url('dinnexltarabic.woff') format('woff');
	//     font-style: normal;
	//     font-display: swap;
	// }


	.switch_launge{
	    width: 190px;
	    font-family: 'Source Sans Pro';
	}

}





.routing{
    
    &.hide{
        display: none;
    }
    ul{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width:768px) {
            flex-wrap: wrap;
        }
        &:hover{
            li{
                a{
                    transform: scale(0.9);
                    opacity: 0.8;
                    z-index: 10;
                }
            }
        }
        li{
            width: 45%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width:768px) {
                margin-bottom: 15px;
            }

            a{
                color: #fff;
                font-size: 23px;
                transition: transform .2s;
                @media screen and (max-width:768px) {
                    font-size: 16px;
                }
                text-transform: capitalize;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                width: 100%;
                padding: 15px 0px;
                border: 2px solid #223057;
                border-radius: 8px;
                text-decoration: none;
                background-color: #00a471;
                img{
                    margin-bottom: 15px;
                }
                &:hover{
                    transform: scale(1.1);
                    opacity: 1;
                    z-index: 500;
                }
                
            }
            &:first-child{
                a{
                    background-color: #32b2c5;
                }
            }
        }
    }
}

#iframe_blog{
    @media screen and (max-width:768px) {
        height: 442px;
    }
}
.backToHome{
    position: absolute;
    left: 15px;
    cursor: pointer;
    transition: all 0.2s; 
    &:hover{
        left:10px;
    }
}
.show_urgent_form .bootstrap-select .btn-light{
    background-color: #fff !important;
}
.form_urgent{
    transition: opacity 0.8s;
    transition: height 0.5s;
    &.hide{
        opacity: 0;
        padding: 0px;
        height: 0px;
        overflow: hidden;
    }
    &.show{
        opacity: 1;
        height: auto;
        overflow: visible;
         
    }
}
.forms-steps{
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    *{
        transition:all 1s;
    }
    .form-step{
        position: absolute;
        top: 0px;
        left: 105%;
    }
    .stepNonActive{
        left: -115%;
        position: absolute;
        opacity: 0;
    }
    .stepActive{
        left: 0;
        position: relative;
    }
}