@import "~scss/variables";

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');

@font-face {
    font-family: 'BentonSansBold';
    src: url($fonts_url + '/bentonsans-bold.woff2') format('woff2'),
        url($fonts_url + '/bentonsans-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSansBook';
    src: url($fonts_url + '/bentonsans-book.woff2') format('woff2'),
        url($fonts_url + '/bentonsans-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSansLight';
    src: url($fonts_url + '/bentonsans-light.woff2') format('woff2'),
        url($fonts_url + '/bentonsans-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

