/* Icon 1 */
#nav-icon2 {
  width: 35px;
  height: 26px;
  position: relative;
  outline: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: #32B2C5;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(even) {left: 50%;border-radius: 0 9px 9px 0;}
    &:nth-child(odd) {left:0px;border-radius: 9px 0 0 9px;}
    &:nth-child(1),&:nth-child(2) {top: 0px;}
    &:nth-child(3),&:nth-child(4) {top: 10px;}
    &:nth-child(5),&:nth-child(6) {top: 20px;}
  }
}
#nav-icon2.open{
  span{
    &:nth-child(1),&:nth-child(6) {-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);}
    &:nth-child(2),&:nth-child(5) {-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);}
    &:nth-child(1) {left: 3px;top: 7px;}
    &:nth-child(2) {left: calc(50% - 5px);top: 7px;}
    &:nth-child(3) {left: -50%;opacity: 0;}
    &:nth-child(4) {left: 100%;opacity: 0;}
    &:nth-child(5) {left: 3px;top: 17px;}
    &:nth-child(6) {left: calc(50% - 5px);top: 17px;}
  }
}



 