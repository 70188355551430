@import "hamburgers";


//  Navbar
.navbar-nav{
	margin-right: 28px;
	a{
		font-size: 18px;
		color: #1A2956;
		&:hover{
			color: #32B2C5;
		}
	}


}

@include screen-tablet {

	a.navbar-brand img{
		width: 150px;
    	padding: 6px 0px;
	}

	nav .container {
	    max-width: 100% !important;
	}
	.navbar-expand-md .navbar-nav .nav-link {
	    padding-right: 0.5rem;
	    padding-left: 0.5rem;
	    text-align: center;
	    
	}
	nav .btn{
	    padding: 0.6rem 0.8rem !important;
	}

	.navbar-nav a {
	    font-size: 16px;
	}
	.navbar-nav {
	    margin-right: 15px;
	}
}
@include screen-mobile {
	.navbar-nav {
	    margin-right: 0px !important;
	    margin-top: 10px;
	}

}

@include screen-mini-mobile {
	.navbar-collapse .btn-primary{
		width: 100%;
	}
}

@include screen-mini-mobile {
  #make_appointment{
    display: none;
  }
}