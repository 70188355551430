footer{
	padding-top: 30px;
	@include screen-mini-mobile {
		text-align: center;
	}
	.menu-title{
		font-family: "Dosis", sans-serif;
		font-size: 16px;
		color: #202c57;
		font-weight: 700;
		margin-bottom: 30px;
		display: block;
		min-height: 24px;
		@include screen-tablet {&:empty{display: none;}}
	}
ul{
	margin-bottom: 30px;
	li{
		line-height: 26px;
		a{
			font-size: 14px;
			font-family: "Dosis", sans-serif;
			font-weight: 500;
			font-size: 14px;
			color: #707070;
			transition: all 0.2s;
			&:hover{
				text-decoration: none;
				color: #202c57;
				letter-spacing: 1.1px;
			}
		}
	}
}
.SocialNetwork{
    display: flex;
    justify-content: space-between;
    max-width: 145px;
    @include screen-mini-mobile {
		margin-left: auto;
    	margin-right: auto;
	}
	li{
		float: left;
		a{
			display: block;
		    border-radius: 45px;
    		&:hover{
    			box-shadow: 0px 0px 15px -5px #000;
    		}
		}
	}
}
.switch_launge{
	@include screen-mini-mobile {
		margin-left: calc(50% - 75px);
	}
}

.footer-middle{
	padding: 30px 0px;
	@include screen-mini-mobile {padding: 0px;}
	*{
		text-align: center;	
		width: 100%;
	}
	.footer-middle-title{
		color: #32B2C5;
		font-size: 20px;
		display: block;
		font-weight: 700;
		margin-bottom: 15px;
		padding: 0px 15px;
	}
	p{
		color: #6D6E71;
		font-size: 15px;
		line-height: 27px;
		font-family: 'BentonSansLight';
		margin: 0px;
		@include screen-mini-mobile {
			width: 90%;
			margin: 0px auto;
		}
	}

}
.footer-bootom{
    background-color: #32B2C5;
    color: #fff;
    font-size: 15px;
    padding: 30px 0px;
    text-align: center;
    font-family: 'BentonSansLight';
    p{
		margin: 0px;
    }
}
}
