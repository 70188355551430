@import "~scss/variables";
ul{
	list-style: none;
	padding: 0px;
}
a{text-decoration: none;}
// *{
// 	transition: all 0.2s
// }
html{
	scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #32B2C5;
}
::-webkit-scrollbar-thumb:window-inactive {
	background: #32B2C5;
}
.title-home{
	color: #202c57;
	position: relative;
    font-family: "Dosis", sans-serif;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    margin: 0px;
    padding: 30px  0px 0px 0px;
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
    @include screen-mini-mobile {font-size: 25px;}
	img{
		margin-left: 15px;
		@include screen-mini-mobile {
			display: block;
			margin: 10px auto 0px auto;
			font-size: 24px;
		}
	}
}
@-moz-keyframes "scroll" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(30px);
	}
}
@-webkit-keyframes "scroll" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(30px);
	}
}
@-o-keyframes "scroll" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(30px);
	}
}
@keyframes "scroll" {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(30px);
	}
}
.icon-scroll {
	position: absolute;
	left: 50%;
	width: 29px;
	height: 44px;
	box-shadow: inset 0 0 0 2px #32B2C5;
	border-radius: 25px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;
	display: block;
	position: relative;
	left: auto;
	margin-top: 10px;
	cursor: pointer;
	&:before {
		position: absolute;
		left: 50%;
		content: '';
		width: 5px;
		height: 5px;
		background: #32B2C5;
		margin-left: -3px;
		top: 4px;
		border-radius: 4px;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-name: scroll;
	}
}
.featurette-heading{
	font-family: "Dosis", sans-serif;
	font-weight: 600;
	font-size: 30px;
	color: #202C57;
	text-align: left;
	@include screen-mobile {
		font-size: 25px;
		line-height: 30px;
	}
}
.marketing h2{
	margin-top: 15px;
}
.featurette{
	img{
		@include screen-tablet {width: 100%;}
	}
}
.featurette-liste{
	font-family: "Dosis", sans-serif;
	color: #202C57;
	font-size: 16px;
	margin: 0px;
    padding: 0px;
    padding-left: 18px;
    margin-top: 30px;
    @include screen-mobile {margin-bottom: 30px;}
	li{
		margin: 10px 0px;
		list-style-image : url($images_url + '/puce.svg');
	}
}
.section-video{
	position: relative;
	background-color: #4E5C93;
	padding: 15px 0px 30px 0px;
	&:before{
		content: url($images_url + '/calc-video-left.svg');
		position: absolute;
	    left: 0px;
    	bottom: -6px;
	}
	&:after{
		content: url($images_url + '/calc-video-right.svg');
		position: absolute;
	    right: 0px;
	    top: -6px;
	}
	h2{
		font-size: 30px;
		color: #fff;
		font-weight: 600;
		text-align: center;
		@include screen-mini-mobile {font-size: 25px;}
	}
	.video-box{
		margin-top: 30px;
		text-align: center;
		img{
			width: 100%;
			max-width: 551px;
			cursor: pointer;
		}
	}
}
.pricing{
	padding: 15px 0px 30px 0px;
	@include screen-mobile {padding: 15px 15px 30px 15px;}
	h2{
		color: #202C57;
		padding: 25px 0px 50px 0px;
		@include screen-mini-mobile {
			font-size: 25px;
			padding: 5px 0px 15px 0px;
		}
	}
	.btn{
		font-size: 20px;
	}
	.btn-primary:hover {
	    color: #fff !important;
	    background-color: #277f89 !important;
	    border-color: #24767f !important;
	}
	.old-price{
		text-decoration: line-through;
	    color: #00a471;
	    font-size: 18px;
	    position: absolute;
	    right: 60px;
	    font-family: 'Source Sans Pro';
	    font-weight: 400;
	}
	.promo{
		font-size: 13px;
	    color: #fff;
	    font-family: "Source Sans Pro";
	    font-weight: 700;
	    background-color: #BE2053;
	    border-radius: 14px;
	    position: absolute;
	    padding: 2px 12px;
	    right: 10px;
	    top: 12px
	}
	.pricing-item{
		border: 2px solid #CACED5;
	    color: #808080;
	    padding: 40px 0px 0px 0px;
	    min-height: 486px;
	    margin-bottom: 30px;
	    @include screen-tablet {min-height: 528px;}
	    @include screen-mini-mobile {
	    	min-height: auto;
	    	padding: 0px;
	    }
	    .card-body{
	    	@include screen-mini-mobile {
		    	padding: 15px 5px;
		    }
	    }
		.card-title{
			font-family: "Dosis", sans-serif;
			font-weight: 600;
			font-size: 30px;
		}
		.pricing-description{
			font-family: 'Source Sans Pro';
			font-size: 18px;
			line-height: 23px;
		    margin: 23px 0px;
		    @include screen-mini-mobile {margin: 23px 0px;}
		}
		.pricing-value{
			font-size: 100px;
		    display: block;
		    line-height: 60px;
		    margin-top: 40px;
		    position: relative;
		    @include screen-mini-mobile {
		    	margin-top: 0px;
		    	line-height: 30px;
		    }
			.sub{
				font-size: 50px;
				@include screen-tablet {
					font-size: 30px;
				}
			}
			@include screen-tablet {
				font-size: 60px;
			}
		}
		.pricing-Period{
			width: 100%;
			font-size: 18px;
			font-weight: 400;
			margin: 30px 0px;
			float: left;
			@include screen-mini-mobile {margin: 15px 0px;}
		}
		.card-footer{
			background-color: transparent;
		    border: 0px;
		    padding: 0px 1.25rem 1rem 1.25rem;
		    .btn{
		    	padding: 0.6rem 3rem;
		    	border: 2px solid #CACED5;
		    }
		}
		&.basic{
			border-color: #32B2C5;
			.card-title,.pricing-value{
				color: #32B2C5;
			}
			.pricing-Period,.pricing-description{
				color: #202C57;
			}
			.btn{
				border: 2px solid #32B2C5;
			}
		}
		&.premium{
			background-color: #32B2C5;
			border-color: #32B2C5;
			*{
				color: #fff;
			}
			.btn{
				border: 2px solid #fff;
				background-color: #fff;
				color: #32B2C5;
				&:hover{
					color: #fff;
					background-color: #32B2C5;
				}
			}
		}
	}
	.pack-detail{
		font-family: "Source Sans Pro";
		font-weight: 400;
		font-size: 16px;
		line-height: 45px;
		color: #585858;
		@include screen-tablet {
			font-size: 13px;
		}
		ul{
			margin:0px;
			padding: 0px;
			width: 100%;
			list-style: none;
			margin-bottom: 15px;
			li{
				border-bottom:1px solid #E8E9EC;
				@include screen-tablet {
					padding: 5px 0px;line-height: 25px;
					&.empty{
						display: none;
					}
				}
				&:first-child{
					border-top:1px solid #E8E9EC;
				}
			}
		}
		&.basic,&.premium{
			color: #32B2C5;
			li:first-child{
				font-weight: 700;
			}
		}
		.card-footer{
			background-color: transparent;
		    border: 0px;
		    .btn{
		    	padding: 0.6rem 3rem;
		    	border: 2px solid #CACED5;
				background-color: #fff;
				color: #626060;
		    }
		}
		&.basic{
			.btn{
				border: 2px solid #32B2C5;
			}
		}
		&.premium{
			.btn{
				border: 2px solid #32B2C5;
				background-color: #32B2C5;
				color: #fff;
			}
		}
	}
}
.CallToAction{
	background-color: #32B2C5;
	text-align: center;
	padding: 30px 0px;
	a{
		font-family: "Dosis", sans-serif;
	    font-size: 40px;
	    text-decoration: none;
	    margin-bottom: 15px;
	    display: inline-block;
	    img{
	    	top: -8px;
    		position: relative;
	    }
	}
	p{
		font-size: 24px;
		font-family: "Source Sans Pro";
		margin-bottom: 0px;
		a{
			font-size: 24px;
    		font-family: "Source Sans Pro";
    		margin-bottom: 0px;
		}
	}
	*{
		color: #fff;
		text-align: center;
	}
}
.modal-content.picto{
	&:before{
		content: url($images_url + '/modal-picto-left.svg');
	    position: absolute;
	    left: -336px;
	    top: calc(50% - 130px);
	    @include screen-tablet {display: none;}
	}
	&:after{
		content: url($images_url + '/modal-picto-right.svg');
		position: absolute;
	    right: -345px;
	    top: calc(50% - 130px);
	    @include screen-tablet {display: none;}
	}
}
.form-modal{
	width: 90%;
	margin: 0px auto;
	.select-lg .btn-light,.input-lg{
		// padding: 0.6rem 1.9rem 0.6rem 2.5rem;
		padding: 0.6rem 1.9rem;
	}
	.alerte{
		font-size: 16px;
		color: #7B7B7B;
		font-family: "Dosis", sans-serif;
	    padding-right: 5px;
    	padding-left: 5px;
    	width: 100%;
    	&.danger{
    		color: #00a471;
    	}
	}
}

.switch_launge {
    font-size: 18px;
    font-family: "terminal_dosissemibold";
    padding: 11px 10px;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    background-color: #32B2C5;
    color: #fff;
    float: left;
    margin-top: -15px;
    border: 1px solid #32B2C5;
    width: 153px;
}

.btn-submit{
	margin: 30px auto;
	display: block;
}
.modal-message{
	width: 80%;
    margin: 0px auto;
    text-align: center;
    @include screen-mobile {width: 90%;}
    img{
    	margin: 15px auto;
    }
	p{
		font-size: 18px;
		font-family: "Dosis", sans-serif;
		color: #525252;
	}
}
.pack-type{
	border: 1px solid;
    font-size: 12px;
    font-family: "Dosis", sans-serif;
    text-align: center;
    padding: 0px 5px;
    border-radius: 2px;
    position: absolute;
    height: 20px;
    text-transform: uppercase;
    line-height: 20px;
    &.free{
    	border-color: #CACED5;
    	color: #9399A3;
    }
    &.basic{
    	color: #32B2C5;
    	border-color: #32B2C5;
    }
    &.premium{
    	color: #fff;
    	background-color: #32B2C5;
    	border-color: #32B2C5;
    }
}
.icon {
	position: absolute;
    margin-top: calc(25px - 15px);
    margin-left: 3px;
    z-index: 10;
    @include screen-mobile {margin-top: calc(25px - 15px);}
	&.icon-position{
		content: url($images_url + '/position-icon.svg');
	}
	&.icon-phone{
		content: url($images_url + '/phone.svg');
	}
	&.icon-mail{
		content: url($images_url + '/mail.svg');
	}
	&.icon-heartbeat{
		content: url($images_url + '/heartbeat.svg');
	}
	&.center-icon{
		content: url($images_url + '/center-icon.svg');
	}
	&.icon-consultation{
    	content-image: url($images_url + '/consultation.svg');
  	}
}
.bootstrap-select.show .dropdown-toggle{
    border-radius: 4px 4px 0px 0px !important;
}
.bs-searchbox{
	.form-control{
		height: 40px !important;
	}
}
.bootstrap-select{
	.dropdown-menu{
	    top: -2px !important;
	    border-radius: 0px 0px 4px 4px !important;
	    border-top: 0px !important;
	    box-shadow: 0px 0px 1px 0px #fff;
	}
	.dropdown-item{
		&.active,:active,:hover,:focus{
				background: rgb(239,240,242);
			background: linear-gradient(90deg, rgba(239,240,242,1) 58%, rgba(239,240,242,0) 100%);
		}
	}
}
.alert-danger {
    color: #00a471;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
	box-shadow: none;
}


#registrationModal{


	#mainLoader{
    	position: absolute;
    	transform: translateY(-150%);
    	-webkit-transition: all .5s ease;
	    -moz-transition: all .5s ease;
	    -ms-transition: all .5s ease;
	     -o-transition: all .5s ease;
	        transition: all .5s ease;
	        svg{
	        	width: 80% !important;
	        	height: 80% !important;
	        	margin-left: auto;
	        	margin-right: auto;
	        	display: block;
	        }
	}
	.modal-body{
		overflow: hidden !important;
	}
	form{
		transform: translateY(0%);
		-webkit-transition: all .5s ease;
       -moz-transition: all .25 ease-;
        -ms-transition: all .5s ease;
         -o-transition: all .5s ease;
            transition: all .5s ease;
	}
	&.loading{

		form{
			transform: translateY(150%);
		}
		#mainLoader{
		    transform: translateY(-30px);
		}
	}
}
