.carousel  {
	background-color: #223057;
	margin-bottom: 0px;
	.container{
		position: relative;
    	height: 100%;
	}
	// .carousel-inner:before{
	// 	content: url($images_url + '/calc-top.svg');
	// 	position: absolute;
	//     left: 0px;
	//     top: 0px;
	// }
	// .carousel-inner:after{
	// 	content: url($images_url + '/calc-bottom.svg');
	// 	position: absolute;
	//     right: 0px;
	//     bottom: -6px;
	// }

	.carousel-item{
		@media screen and (max-width:768px) {
			max-height: 500px;
		}
	}
	input, input:before, input:after {
      -webkit-user-select: initial;
      -khtml-user-select: initial;
      -moz-user-select: initial;
      -ms-user-select: initial;
      user-select: initial;
 	} 

	.carousel-caption {
	  transform: translateY(-50%);
	  bottom: 0;
	  top: 50%;
	  right: 0px;
	  left:0px;
	  display: flex;
    	align-items: center;
	  @media screen and (max-width:768px) {
	  	top: 30%;
	  }
	  .btn{
	  	width: 100%;
	  	max-width: 280px;
	  }
	}

	.carousel-caption-text{
		position: relative;
	    max-width: 400px;
	    @include screen-tablet {
	    	max-width: 50%;
	    }
	    width: 100%;
	    text-align: center;
	}

	@media screen and (max-width:768px) {
		.carousel-caption-text{
			max-width: 100%;
			padding: 0px 30px;
		}
		.carousel-caption-img{display: none;}
		.carousel-caption p{
			width: 100%;
		}
	}

	.carousel-caption-img{
        position: absolute;
	    right: 0%;
	    top: -15%;
	    @include screen-MacBookPro {margin-top: -23px;}
	    width: calc(100% - 276px);
	    img{
	    	position: absolute;
	    	height: auto;
    		max-width: 100%;
	    }
	    .item1{
	        top: 46px;
		    @include screen-MacBookPro {top: 46px;}
		    right: 0%;
		    z-index: 15;
		    width: 160px;
	    }
	    .item2{
		    top: 0px;
		    right: 20%;
		    z-index: 28;
		    width: 200px;
	    }
	    .item3{
	        top: 46px;
		    @include screen-MacBookPro {top: 46px;}
		    right:  45%;
		    z-index: 15;
		    width: 160px;
	    }
	}




}


nav .container{
	&:before,&:after{
		display: none;
	}
}


.search-section{
	background-color: #223057;
	margin-bottom: 0px;
	padding-bottom: 30px;
	@media screen and (max-width:768px) {
	    padding: 0px 15px 30px 15px;
	    overflow: hidden;
	}
	.container{
		position: relative;
    	height: 100%;
	}
	.search-form{
	    background-color: #3B4971;
    	// padding: 30px;
    	transition: all .4s ease;
				padding: 0px;
				top: 250px;
				height: 0px;
				overflow: hidden;
				&.show{
					padding: 15px;
					top: 0px;
					display: block;
					overflow: visible;
					max-height: 325px;
	    			height: auto;
				}
			
    	border-radius: 9px;
    	z-index: 100;
    	select{
    		width: 100%;
    		background-color: #fff;
    		height: 50px;
	    	border: 1px solid transparent;
		    padding: 0.6rem 1.9rem;
		    font-size: 1rem;
		    line-height: 1.5;
		    border-radius: 4px;
		    color: #223057;
    	}
    	.title-form{
		    text-align: center;
		    font-size: 25px;
		    @media screen and (max-width:768px) {
		    	font-size: 20px;
    			margin-top: 0px;
		    }
		    color: #fff;
		    margin-top: -10px;
		}
  	.form-row {
			flex-wrap: nowrap;
			justify-content: space-evenly;
			@media screen and (max-width:768px) {
				flex-wrap: wrap;
			}
		}
		 
	}
	.bootstrap-select{
		background-color: #fff;
	    border-radius: 4px;
	    @include screen-tablet {
	    	margin-bottom: 15px;
	    }
	    .btn-light{
	    	@include screen-tablet {
	    		height: 50px;
	    	}
	    }
	}

	.icon-heartbeat{
		z-index: 100;
		margin-top: calc(27px - 15px);
    	margin-left: 5px;
	}
	.form-row > [class*=col-]{
		margin-bottom: 0px;
	}
	.btn-primary{
		padding: 12px 0px;
	}
	.filter-option-inner{
		line-height: 30px;
	}
	.bootstrap-select{
		.dropdown-item{
			background: #fff;
			&.active,&:active,&:hover,&:focus{
				background: #3b4971;
			    background: linear-gradient(90deg, #223057 58%, #3b4971 100%);
			    color: #ffffff;
			    span{
			    	background: transparent;
			    }
			}
		}

		.dropdown-toggle {
			.filter-option-inner-inner{
				color: #223057;
		    	padding-left: 10px;
		    	font-size: 19px;
			}
			&:after{

			}	    
		}


		.dropdown-header {
		    font-size: 1rem;
		    color: #324067;
		    background-color: #2230570d;
		}

	}
}

.booking-steps{
	p{
		text-align: center;
	    color: #1C2856;
	    font-size: 17px;
	    margin-top: 30px;
	    padding: 0px 35px;
	}
}
.height-90{
	height: 90px;
	width: 100%;
	display: block;
	@media screen and (max-width:768px) {
		height: 30px;
	}
}
.height-60{
	height: 60px;
	width: 100%;
	display: block;
	@media screen and (max-width:768px) {
		height: 15px;
	}
}

.results-section{
	background-color: #fff;
	position: relative;
	overflow: hidden;
	height: 0px;
	transition: all 0.5s ease-in-out;
	 
	&.show{
		height: 570px;
		@include screen-tablet {
			height: 990px;	
		}


		
	}
	@media screen and (max-width:768px) {
	    &.show{
			padding: 0px 15px 30px 15px;
			height: 700px;
		}
	}
	&:before{
		content: '';
		width: 100%;
		height: 95px;
		@media screen and (max-width:768px) {
			height: 75px;
		}
		background-color: #223057;
		position: absolute;
		top: 0px;
		left: 0px
	}
	.steps-box{
		@media screen and (max-width:768px) {
			display: none;
		}
	}

	.form-row > [class*=col-]{
		margin-bottom: 0px;
	}
	.search-form{
		background-color: #3b4a734d;
		padding: 30px;
		@media screen and (max-width:768px) {
			padding: 15px;
		}
		border-radius: 8px;
	}
	.header{
		background-color: #32B2C5;
		font-size: 23px;
		width: 100%;
		padding: 15px 20px;
		@include screen-tablet {
			display: flex;
    		justify-content: center;
		}
		@media screen and (max-width:768px) {
			padding: 15px;
			font-size: 1rem;
		}
		color: #fff;
		border-radius: 4px 4px 0px 0px;
		img{
			margin-right: 15px;
			@media screen and (max-width:768px) {
				margin-right: 5px;
			}
		}
	}
	.body{
		background-color: #fff;
		padding: 30px 30px 15px 30px;
		.steps-title{
			color: #1C2856;
			font-size: 19px;
		}
		ul{
			li{
				color: #1C2856;
				font-size: 16px;
			}
		}
	}
	.footer{
		width: 100%;
		text-align: center;
		padding: 0px 30px;
		background-color: #fff;
		border-radius: 0px 0px 4px 4px;
		a{
		    width: 100%;
		    padding: 15px 0px;
		    border-top: 1px solid #C7C8CA;
		    display: block;
		    color: #CA2952;
		    text-decoration: none;
		    transition: all 0.2s;
		    &:hover{
		    	letter-spacing: 1.2px;
		    	font-weight: bold;
		    }
		}
	}
}

.form-row{
	*{
		transition: all 0.4s;
	}
}

.specialities-box{
  margin-left: 0%;
  &.d-none{
    display: block !important;
    width: 0px !important;
    overflow: hidden;
    padding: 0px !important;
    opacity: 0;
    @media screen and (max-width:768px) {
    	width: auto !important;
    	height: 0px;
    }
  }
}
.centers-box{
  margin-left: 0%;
  max-width: 250px;
  @media screen and (max-width:768px) {
  	max-width: 100%;
  }
  &.d-none{
    display: block !important;
    width: 0px !important;
    overflow: hidden;
    padding: 0px !important;
    opacity: 0;
    @media screen and (max-width:768px) {
    	max-width: 100%;
    	width: auto !important;
    	height: 0px;
    }
  }
}


@media screen and (min-width:768px) {
	.ellipsis{
		.filter-option-inner-inner{
			max-width: 150px;
		  white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
		}
	}
}

 