// stylelint-disable declaration-no-important

//
// Visibility utilities
//

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.hidden {
  display: none;
}

.block {
  display: block;
}
